<template>
  <div id="displayCard" style="margin-bottom: 100px">
    <confirm-dlg ref="confirm" />
    <event-bazaar :eventName="this.eventName" v-if="this.eventName" :stickyPanel="true" />
    <v-card class="bazaarCard" :style="this.eventName ? 'border-top: 5px solid #8bb35d' : ''" v-if="!eventName">
      <div style="display: flex; align-items: center; margin-bottom: 24px">
        <h1>
          {{ $t("bazaarTop") }}<span v-if="eventName">{{ " " }} - {{ " " }}{{ this.eventName }}</span>
        </h1>
      </div>
      <!-- <event-landing-gallery class="mb-10" :enableColorScheme="false" padding="0px" /> -->
      <div class="searchToolbar" style="margin-bottom: 20px">
        <v-text-field
          filled
          rounded
          single-line
          hide-details
          full-width
          v-model="searchInput"
          data-cy="searchInput"
          style="border-radius: 30px; width: 800px"
          @keydown.enter="initSearch"
          @click:clear="clearSearch"
          clearable
        >
          <template v-slot:prepend-inner>
            <v-icon color="primary">mdi-magnify</v-icon>
          </template>
        </v-text-field>
        <v-spacer></v-spacer>
        <v-btn large icon @click="filterMenu = !filterMenu" class="ml-2" data-cy="filterToggle">
          <v-icon large v-if="filterMenu">mdi-chevron-up</v-icon>
          <v-icon large v-else>mdi-chevron-down</v-icon>
        </v-btn>

        <v-btn color="primary" fab size="50px" @click="newItem" class="floatingButton" data-cy="floatingUploadButton"
          ><v-icon x-large>mdi-file-upload-outline</v-icon>
        </v-btn>
      </div>
      <div class="my-5">
        <v-expand-transition>
          <div v-show="filterMenu">
            <v-card flat outlined style="border-radius: 10px" class="pa-5">
              <v-row>
                <v-col cols="12" sm="6" :lg="`${eventHistory.length > 0 ? 4 : 6}`">
                  <h5 class="mb-3">{{ $t("bazaarSortBy") }}</h5>
                  <v-select
                    hide-details=""
                    outlined
                    dense
                    v-model="sortBy"
                    :items="sortByOptions"
                    item-text="label"
                    item-value="value"
                    @change="changeSearchParams"
                    style="max-width: 200px"
                    data-cy="sortBy"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" :lg="`${eventHistory.length > 0 ? 4 : 6}`">
                  <h5 class="ma-0 mb-3 pa-0">{{ $t("bazaarSelect") }}</h5>
                  <v-radio-group v-model="searchField" class="ma-0" hide-details="" row @change="changeSearchParams">
                    <v-radio :label="$t('bazaarAll')" value="all" data-cy="searchByAll"></v-radio>
                    <v-radio :label="$t('createTitle')" value="title" data-cy="searchByTitle"></v-radio>
                    <v-radio
                      :label="$t('createDescription')"
                      value="description"
                      data-cy="searchByDescription"
                    ></v-radio>
                    <v-radio :label="$t('viewCreator')" value="creatorSearch" data-cy="searchByCreator"></v-radio>
                    <v-radio :label="$t('viewOwner')" value="ownerSearch" data-cy="searchByOwner"></v-radio>
                    <v-radio :label="$t('bazaarHashtagSearch')" value="hashtag" data-cy="searchByHashtag"></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" sm="6" lg="4" v-if="eventHistory.length > 0">
                  <h5 class="mb-3" v-if="eventHistory && eventHistory.length > 0">{{ $t("bazaarRecentEvent") }}</h5>
                  <v-chip
                    v-for="(event, i) in eventHistory"
                    :key="i"
                    close
                    @click="goToEvent(event)"
                    @click:close="removeEventHistory(event)"
                    :color="event === searchParams['event-name-search'] ? 'primary' : ''"
                    :style="{ 'max-width': $screen.width < 600 ? '100%' : '200px' }"
                    :large="$screen.width < 600"
                    ><span class="text-truncate">{{ event }}</span>
                  </v-chip>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-expand-transition>
      </div>
      <bazaar-gallery :items="items" :loading="loading" />
      <div v-if="loading" class="d-flex align-center justify-center" key="skeleton">
        <v-row>
          <v-col cols="6" sm="4" md="3" xl="2" v-for="(item, i) in itemsPerPage" :key="i">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <!-- <div class="platformFooterBg" v-if="!this.searchMode"></div> -->

    <!-- <div class="platformFooter" v-if="!this.searchMode">
      <div id="platformFooterRows">
        <p style="margin: 0">{{ $t("bazaarPageNumber") }}</p>
        <v-select
          v-model="itemsPerPage"
          :items="[12, 24, 36, 48]"
          @change="changeNumberOfItems"
          item-value
          filled
          dense
          hide-details=""
          class="selectNoBorder"
          data-cy="itemsPerPage"
        >
        </v-select>
      </div>
      <v-spacer></v-spacer>
      <div id="platformFooterItem">
        <p style="margin: 0">
          {{ this.$t("bazaarPage", { pageNumber, pageCount }) }}
        </p>
        <v-btn icon @click="prevPage" :disabled="pageNumber === 1" data-cy="prevPage">
          <v-icon>mdi-menu-left</v-icon>
        </v-btn>
        <v-btn icon @click="nextPage" :disabled="pageNumber === pageCount" data-cy="nextPage">
          <v-icon>mdi-menu-right</v-icon>
        </v-btn>
      </div>
    </div> -->
  </div>
</template>

<script>
import PlatformLayout from "@/layouts/Platform";
import { displayWorksPage } from "../../services/workService";
import config from "../../config";
import ConfirmDlg from "../../components/Platform/ConfirmDlg.vue";
import EventBazaar from "@/components/Common/EventBazaar.vue";
import BazaarGallery from "@/components/Common/BazaarGallery.vue";
// import EventLandingGallery from "@/components/Home/EventLandingGallery.vue";

export default {
  name: "Bazaar",
  components: {
    ConfirmDlg,
    EventBazaar,
    BazaarGallery
    // EventLandingGallery
  },
  created() {
    this.$emit(`update:layout`, PlatformLayout);
  },
  mounted() {
    if (this.keyword) {
      this.initialise();
      this.searchInput = this.keyword;
      this.searchBazaar();
    } else if (this.hashtag) {
      this.searchInput = this.hashtag;
      this.searchField = "hashtag";
      this.searchBazaar();
    } else {
      this.initialise();
    }
  },

  data: (vm) => ({
    searchField: "all",
    searchInput: "",
    itemsPerPage: null,
    pageCount: 1,
    items: [],
    loading: true,
    searchMode: false,
    searched: false,
    filterMenu: false,
    sortBy: "time",
    searchParams: {
      "items-per-page": null,
      "page-number": null,
      "work-info-search": null,
      "unmanaged-info-search": null,
      "sorting-by-time": true,
      "sorting-by-like": false
    },
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    },
    sortByOptions: [
      {
        label: vm.$t("bazaarSortByNewest"),
        value: "time"
      },
      {
        label: vm.$t("bazaarSortByLike"),
        value: "like"
      }
    ]
  }),
  computed: {
    pageNumber() {
      return this.$store.state.bazaarPageNumber;
    },
    eventName() {
      return this.$route.query.eventName;
    },
    hashtag() {
      return this.$route.query.hashtag;
    },
    keyword() {
      return this.$route.query.keyword;
    },
    creatorSearch() {
      return this.$route.query.creatorSearch;
    },
    ownerSearch() {
      return this.$router.query.ownerSearch;
    },
    eventHistory() {
      let eventHistory = [];
      if (Object.hasOwnProperty.call(this.$store.state.userProfile, "roleInfo")) {
        if (Object.hasOwnProperty.call(this.$store.state.userProfile.roleInfo, "eventNameHistory")) {
          eventHistory = this.$store.state.userProfile.roleInfo.eventNameHistory;
          eventHistory = eventHistory
            .map((e) => {
              if (e) {
                return e.trim();
              } else {
                return null;
              }
            })
            .filter(function (item, pos) {
              return eventHistory.indexOf(item) == pos;
            });
        }
      }

      return eventHistory;
    }
  },
  methods: {
    async initialise() {
      // let itemsPerPage = localStorage.getItem("itemsPerPage");
      // if (itemsPerPage) {
      //   this.itemsPerPage = parseInt(itemsPerPage);
      //   this.searchParams["items-per-page"] = this.itemsPerPage;
      //   this.searchParams["page-number"] = this.pageNumber;
      // }

      if (this.eventName && this.eventName.replace(/\s/g, "") !== "") {
        this.searchMode = true;
        this.searchParams["event-name-search"] = this.eventName;
        this.$store.dispatch("addEventNameToUserInfo", {
          axios: this.$axios,
          config: config,
          eventName: this.eventName,
          token: this.$session.get("token")
        });
        this.$store.commit("setEventName", this.eventName);
        this.$store.dispatch("getEventBazaarList", { axios: this.$axios, config: config, eventName: this.eventName });
      } else if (this.$store.state.searchParams.eventName) {
        this.searchMode = true;
        this.searchParams["event-name-search"] = this.$store.state.searchParams.eventName;
        this.$router
          .push({ path: `/platform/bazaar`, query: { eventName: this.$store.state.searchParams.eventName } })
          .catch((e) => e);
        this.$store.dispatch("addEventNameToUserInfo", {
          axios: this.$axios,
          config: config,
          eventName: this.$store.state.searchParams.eventName,
          token: this.$session.get("token")
        });
      }
      this.loading = true;
      const result = await displayWorksPage(this.$axios, config, this.searchParams);
      this.loading = false;

      if (result.status === 200) {
        this.pageCount = result.numberOfWorks === 0 ? 1 : Math.ceil(result.numberOfWorks / this.itemsPerPage);

        this.items = result.data;
        this.$store.commit("showProgressLine", false);
      } else if (result.status === 500) {
        this.messageBox.content = this.$t("dialogMsg.myCollection.blockchainFail");
        this.messageBox.state = true;
        this.messageBox.destination = "";
        this.$store.commit("showProgressLine", false);
      } else {
        try {
          if (await this.$refs.confirm.open("An error occurred", "Retry?")) {
            this.initialise();
          } else {
            this.logout();
          }
        } catch {
          (e) => e;
        }
      }
      if (this.eventName) {
        this.$store.commit("setBackDestination", {
          show: true,
          path: "/platform/bazaar",
          name: this.$t("titleBazaar"),
          callback: null
        });
      } else {
        this.$store.commit("hideBackDestination");
      }
      this.loading = false;
    },
    clearSearch() {
      this.searchMode = false;
      this.resetSearchParams();
      this.$store.commit("bazaarPageReset");

      this.$router
        .push({
          path: "/platform/bazaar",
          query: { eventName: this.eventName }
        })
        .catch((e) => e);
      this.initialise();
    },
    viewArtwork(workid) {
      this.$router.push({
        name: "ViewArtwork",
        params: {
          workid: workid
        }
      });
    },
    prevPage() {
      this.$store.commit("bazaarPrevPage");
      this.searchParams["page-number"] = this.pageNumber;
      if (this.searchInput) {
        this.searchBazaar();
      } else {
        this.initialise();
      }
    },
    nextPage() {
      this.$store.commit("bazaarNextPage");
      this.searchParams["page-number"] = this.pageNumber;
      if (this.searchInput) {
        this.searchBazaar();
      } else {
        this.initialise();
      }
    },
    changeNumberOfItems() {
      localStorage.setItem("itemsPerPage", this.itemsPerPage);
      this.$store.commit("bazaarPageReset");
      this.initialise();
    },
    initSearch() {
      this.$store.commit("bazaarPageReset");
      this.searchBazaar();
    },
    async searchBazaar() {
      if (this.searchInput) {
        let unmanagedInfo, numberOfWorks, workInfo, pathQuery, allResults, creatorSearch, ownerSearch;
        let tempSearchParam = this.searchParams;
        this.loading = true;
        this.searchMode = true;
        switch (this.searchField) {
          case "all":
            pathQuery = {
              keyword: this.searchInput
            };
            if (this.eventName) {
              pathQuery.eventName = this.eventName;
            }
            this.$router
              .push({
                path: `/platform/bazaar`,
                query: pathQuery
              })
              .catch((e) => e);

            tempSearchParam["items-per-page"] = null;
            tempSearchParam["page-number"] = null;
            tempSearchParam["work-info-search"] = null;
            tempSearchParam["unmanaged-info-search"] = null;
            tempSearchParam["all-search"] = this.searchInput;
            tempSearchParam["creator-search"] = null;
            tempSearchParam["owner-search"] = null;

            allResults = await displayWorksPage(this.$axios, config, tempSearchParam);
            this.items = allResults.data;
            numberOfWorks = allResults.numberOfWorks;

            this.pageCount = numberOfWorks === 0 ? 1 : Math.ceil(numberOfWorks / this.itemsPerPage);
            this.loading = false;
            return;
          case "hashtag":
            tempSearchParam["items-per-page"] = null;
            tempSearchParam["page-number"] = null;
            tempSearchParam["work-info-search"] = null;
            tempSearchParam["unmanaged-info-search"] = this.searchInput;
            tempSearchParam["all-search"] = null;
            tempSearchParam["creator-search"] = null;
            tempSearchParam["owner-search"] = null;

            unmanagedInfo = await displayWorksPage(this.$axios, config, tempSearchParam);
            await unmanagedInfo;

            this.items = unmanagedInfo.data.filter((elem) => {
              let hashtags = elem.unmanagedInfo.hashTags;
              if (!hashtags) {
                return false;
              }
              if (
                hashtags.some((e) => {
                  return e.toUpperCase() === this.searchInput.toUpperCase();
                })
              ) {
                return true;
              } else {
                return false;
              }
            });

            numberOfWorks = unmanagedInfo.numberOfWorks;
            this.pageCount = Math.ceil(numberOfWorks / this.itemsPerPage);
            this.loading = false;
            return;
          case "title":
            tempSearchParam["items-per-page"] = null;
            tempSearchParam["page-number"] = null;
            tempSearchParam["work-info-search"] = this.searchInput;
            tempSearchParam["unmanaged-info-search"] = null;
            tempSearchParam["creator-search"] = null;
            tempSearchParam["owner-search"] = null;
            tempSearchParam["all-search"] = null;

            workInfo = await displayWorksPage(this.$axios, config, tempSearchParam);

            this.items = workInfo.data.filter((elem) => {
              let title = elem.workInfo.artworkTitle;

              if (!title) {
                return false;
              }
              if (title.toUpperCase().indexOf(this.searchInput.toUpperCase()) > -1) {
                return true;
              } else {
                return false;
              }
            });

            numberOfWorks = workInfo.numberOfWorks;
            if (this.pageNumber === 1 && this.items.length < this.itemsPerPage) {
              this.pageCount = 1;
            } else {
              this.pageCount = numberOfWorks === 0 ? 1 : Math.ceil(numberOfWorks / this.itemsPerPage);
            }
            this.loading = false;
            return;
          case "description":
            tempSearchParam["items-per-page"] = null;
            tempSearchParam["page-number"] = null;
            tempSearchParam["work-info-search"] = null;
            tempSearchParam["unmanaged-info-search"] = this.searchInput;
            tempSearchParam["creator-search"] = null;
            tempSearchParam["owner-search"] = null;
            tempSearchParam["all-search"] = null;
            unmanagedInfo = await displayWorksPage(this.$axios, config, tempSearchParam);

            this.items = unmanagedInfo.data.filter((elem) => {
              let description = elem.unmanagedInfo.description;
              if (!description) {
                return false;
              }
              if (description.toUpperCase().indexOf(this.searchInput.toUpperCase()) > -1) {
                return true;
              } else {
                return false;
              }
            });

            numberOfWorks = unmanagedInfo.numberOfWorks;
            if (this.pageNumber === 1 && this.items.length < this.itemsPerPage) {
              this.pageCount = 1;
            } else {
              this.pageCount = numberOfWorks === 0 ? 1 : Math.ceil(numberOfWorks / this.itemsPerPage);
            }
            this.loading = false;
            return;
          case "creatorSearch":
            tempSearchParam["items-per-page"] = null;
            tempSearchParam["page-number"] = null;
            tempSearchParam["work-info-search"] = null;
            tempSearchParam["unmanaged-info-search"] = null;
            tempSearchParam["creator-search"] = this.searchInput;
            tempSearchParam["owner-search"] = null;
            tempSearchParam["all-search"] = null;

            creatorSearch = await displayWorksPage(this.$axios, config, tempSearchParam);

            this.items = creatorSearch.data;

            numberOfWorks = creatorSearch.numberOfWorks;
            if (this.pageNumber === 1 && this.items.length < this.itemsPerPage) {
              this.pageCount = 1;
            } else {
              this.pageCount = numberOfWorks === 0 ? 1 : Math.ceil(numberOfWorks / this.itemsPerPage);
            }
            this.loading = false;
            return;
          case "ownerSearch":
            tempSearchParam["items-per-page"] = null;
            tempSearchParam["page-number"] = null;
            tempSearchParam["work-info-search"] = null;
            tempSearchParam["unmanaged-info-search"] = null;
            tempSearchParam["owner-search"] = this.searchInput;
            tempSearchParam["creator-search"] = null;
            tempSearchParam["all-search"] = null;

            ownerSearch = await displayWorksPage(this.$axios, config, tempSearchParam);

            this.items = ownerSearch.data;

            numberOfWorks = ownerSearch.numberOfWorks;
            if (this.pageNumber === 1 && this.items.length < this.itemsPerPage) {
              this.pageCount = 1;
            } else {
              this.pageCount = numberOfWorks === 0 ? 1 : Math.ceil(numberOfWorks / this.itemsPerPage);
            }
            this.loading = false;
            return;
        }
      }
    },
    removeDuplicates(duplicates) {
      const flag = {};
      const unique = [];
      duplicates.forEach((elem) => {
        if (!flag[elem.workid]) {
          flag[elem.workid] = true;
          unique.push(elem);
        }
      });
      return unique;
    },
    changeSearchParams(event) {
      if (this.sortBy === "time") {
        this.searchParams["sorting-by-time"] = true;
        this.searchParams["sorting-by-like"] = false;
      } else {
        this.searchParams["sorting-by-time"] = false;
        this.searchParams["sorting-by-like"] = true;
      }

      if (event === "like" || event === "time" || (this.searchInput && this.searchInput !== "")) {
        this.initialise();
        this.initSearch();
      }
    },
    viewHashtagWorks(hashtag) {
      this.$router.push(`/platform/bazaar?hashtag=${hashtag}`).catch((e) => e);
      this.searchField = "hashtag";
      this.searchInput = hashtag;
      this.searchMode = true;
      this.filterMenu = false;
      this.initSearch();
    },
    logout: function () {
      this.$store.dispatch("clearImageURL");
      this.$session.destroy();
      this.$router.push("/login").catch((e) => e);
      this.$store.commit("addr", null);
      this.$store.commit("changeState", false);
      this.$store.commit("refreshUserProfile", {});
      localStorage.removeItem("workLastUpdate");

      this.$store.dispatch("resetState");
    },
    noTitle(item) {
      if (!item.workInfo.artworkTitle) {
        return true;
      }
      if (!item.workInfo.artworkTitle.replace(/\s/g, "").length) {
        return true;
      }
      return false;
    },
    async removeEventHistory(event) {
      if (await this.$refs.confirm.open(this.$t("dialogMsg.bazaar.removeEvent"), "")) {
        this.$store.dispatch("removeEventNameToUserInfo", {
          axios: this.$axios,
          config,
          eventName: event,
          token: this.$session.get("token")
        });
        this.filterMenu = false;
        this.exitEvent();
        this.resetSearchParams();
        this.initialise();
        this.$store.dispatch("loadUserProfile", {
          axios: this.$axios,
          config: config,
          token: this.$session.get("token")
        });
      }
    },
    goToEvent(event) {
      if (event === this.eventName) {
        return;
      }
      this.searchMode = true;
      this.searchInput = "";
      this.searchParams["all-search"] = null;
      this.searchParams["work-info-search"] = null;
      this.searchParams["unmanaged-info-search"] = null;
      this.searchParams["creator-search"] = null;
      this.searchParams["owner-search"] = null;
      this.$store.commit("bazaarPageReset");
      this.$store.commit("setEventName", event);
      this.$store.dispatch("getEventBazaarList", { axios: this.$axios, config: config, eventName: event });
      if (this.searchParams["event-name-search"] === event) {
        this.searchParams["event-name-search"] = null;
        this.$router.push(`/platform/bazaar`).catch((e) => e);
      } else {
        this.searchParams["event-name-search"] = event;
        this.$router.push({ path: `/platform/bazaar`, query: { eventName: event } }).catch((e) => e);
      }

      this.filterMenu = false;
      this.initialise();
    },
    exitEvent() {
      this.searchMode = true;
      this.searchInput = "";
      this.resetSearchParams();
      this.searchParams["event-name-search"] = null;
      this.$store.commit("setEventName", null);
      this.$router.push("/platform/bazaar").catch((e) => e);
      this.$store.commit("hideBackDestination");
      this.$store.dispatch("getEventBazaarList", { axios: this.$axios, config: config, eventName: null });
      this.initialise();
    },
    newItem() {
      this.$router.push(`/platform/mycollection?newItem=true`).catch((e) => e);
    },
    resetSearchParams() {
      this.searchMode = false;
      // this.searchParams["page-number"] = 1;
      // this.searchParams["items-per-page"] = this.itemsPerPage;
      this.searchParams["work-info-search"] = null;
      this.searchParams["unmanaged-info-search"] = null;
      this.searchParams["all-search"] = null;
      this.searchParams["creator-search"] = null;
      this.searchParams["owner-search"] = null;

      this.searchField = "all";
    },
    cleanKeyPhotoIndex(i) {
      if (!i) {
        return 0;
      }
      if (i < 0) {
        return 0;
      }
      return i;
    }
  },
  watch: {
    eventName: function (newValue) {
      if (!newValue) {
        this.$store.commit("hideBackDestination");
        this.exitEvent();
        this.resetSearchParams();
        this.initialise();
      }
    }
  }
};
</script>

<style scoped>
.bazaar-elem-enter-active,
.bazaar-elem-leave-active {
  transition: opacity 0.1s ease-in-out, transform 0.1s ease;
}

.bazaar-elem-enter-active {
  transition-delay: 0.1s;
}

.bazaar-elem-enter {
  opacity: 0;
  transform: translateY(20px);
}

.bazaar-elem-enter-to {
  opacity: 1;
  transform: translateY(0px);
}

.bazaar-elem-leave {
  opacity: 1;
  transform: translateY(0px);
}

.bazaar-elem-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.hover {
}
</style>
