<template>
  <div id="carouselImage" @click="viewArtwork(item.workid)">
    <div id="imageContainer">
      <ImageFromFileDescriptor
        :fileDescriptor="item.coverPhotos[cleanKeyPhotoIndex(item.unmanagedInfo.keyPhoto)]"
        :aspectRatio="aspectRatio"
      />
    </div>
    <h4
      style="font-weight: bold; max-width: 100%; overflow: hidden; text-overflow: ellipsis"
      class="mt-3 pb-0 mb-0"
      v-if="showTitle"
    >
      {{ item.workInfo.artworkTitle }}
    </h4>
    <name-tag-from-address v-if="showUserId" :address="item.creatorAddress" class="my-1" />
    <div class="chips" v-if="showHashtag">
      <div class="greenChip" v-for="(hashtag, i) in item.unmanagedInfo.hashTags" :key="i">
        {{ hashtag }}
      </div>
    </div>
    <artwork-display-dialog :item="item" v-model="open" />
  </div>
</template>

<script>
import NameTagFromAddress from "../Common/NameTagFromAddress.vue";
export default {
  data: () => ({
    open: false
  }),
  props: {
    item: {
      type: Object
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showHashtag: {
      type: Boolean,
      default: true
    },
    showUserId: {
      type: Boolean,
      default: true
    },
    aspectRatio: {
      default: "0.75"
    }
  },
  components: {
    ImageFromFileDescriptor: () => import("../../components/Common/ImageFromFileDescriptor.vue"),
    ArtworkDisplayDialog: () => import("@/components/Common/ArtworkDisplayDialog.vue"),
    NameTagFromAddress
  },
  methods: {
    viewArtwork(workid) {
      const loggedIn = this.$session.exists("token");
      const isBazaar = this.$route.path === "/platform/bazaar";
      if (loggedIn || isBazaar) {
        this.$router.push({
          name: "ViewArtwork",
          params: {
            workid: workid
          }
        });
      } else {
        // this.$router
        //   .push({
        //     path: `/platform/artwork/${workid}`
        //   })
        //   .catch((e) => e);
        this.open = true;
      }
    },
    cleanKeyPhotoIndex(i) {
      if (!i) {
        return 0;
      }
      if (i < 0) {
        return 0;
      }
      return i;
    }
  }
};
</script>

<style></style>
